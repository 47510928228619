<template>
  <div
    v-if="
      $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) && organization
    "
  >
    <router-link :to="$objectViewRoute(organization)">
      {{ `${organization.name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "organization",

  components: {},

  mixins: [formMixin],

  props: {
    organization: {
      type: Object,
      default: () => ({}),
      description: "Organization",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    organization(organization) {},
  },
};
</script>
