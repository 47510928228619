import service from "@/store/services/warehouse-products-service";

export const state = () => ({
  list: {},
  warehouseProduct: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, warehouseProduct) => {
    state.warehouseProduct = warehouseProduct;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((warehouseProduct) => {
      commit("SET_RESOURCE", warehouseProduct);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((warehouseProduct) => {
      commit("SET_RESOURCE", warehouseProduct);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((warehouseProduct) => {
      commit("SET_RESOURCE", warehouseProduct);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  warehouseProduct: (state) => state.warehouseProduct,
  url: (state) => state.url,
};

const warehouseProducts = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default warehouseProducts;
