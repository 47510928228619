export const TYPE_FACEBOOK = "FACEBOOK";
export const TYPE_LINKEDIN = "LINKEDIN";
export const TYPE_HUISSIERS = "HUISSIERS";
export const TYPE_411 = "411";
export const TYPE_TELEPHONE = "TELEPHONE";
export const TYPE_COURRIEL = "COURRIEL";
export const EXPEDITION_POSTE = "EXPEDITION_POSTE";

export const COMMUNICATIONS_TYPES = [
  TYPE_FACEBOOK,
  TYPE_LINKEDIN,
  TYPE_HUISSIERS,
  TYPE_411,
  TYPE_TELEPHONE,
  TYPE_COURRIEL,
  EXPEDITION_POSTE,
];