import service from "@/store/services/notifications-service";
import Jsona from "jsona";

const jsona = new Jsona();

export const state = () => ({
  list: {},
  notification: {},
  meta: {},
  url: null,
  refreshKey: 0,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, notification) => {
    state.notification = notification;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  INSCREMENT_REFRESH_KEY: (state) => {
    state.refreshKey = state.refreshKey + 1;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((notification) => {
      commit("SET_RESOURCE", notification);
      commit("INSCREMENT_REFRESH_KEY");
    });
  },

  markAsRead({ commit, dispatch }, params) {
    return service.markAsRead(params, this.$axios).then((notification) => {
      commit("INSCREMENT_REFRESH_KEY");
    });
  },

  markAsUnRead({ commit, dispatch }, params) {
    return service.markAsUnRead(params, this.$axios).then((notification) => {
      commit("INSCREMENT_REFRESH_KEY");
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  notification: (state) => state.notification,
  url: (state) => state.url,
  refreshKey: (state) => state.refreshKey,
};

const notifications = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default notifications;
