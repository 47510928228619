export default {
  bind(el, binding, vnode) {
    let startX;

    let resizeDiv = document.createElement("div");
    resizeDiv.className = "resize-div";
    resizeDiv.style.left = "0";
    el.prepend(resizeDiv);

    if (el.querySelector(".expand")) {
      el.querySelector(".expand").addEventListener("mouseup", () => {
        el.classList.toggle("resize-full");
      });
    }
    const handleMouseDown = (e) => {
      startX = e.clientX;
      document.documentElement.addEventListener("mousemove", handleMouseMove);
      document.documentElement.addEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = "none";
      resizeDiv.style.backgroundColor = "white";
    };

    const handleMouseMove = (e) => {
      const leftPositon = e.clientX - startX;
      resizeDiv.style.left = `${leftPositon}px`;
    };

    const handleMouseUp = (e) => {
      document.documentElement.removeEventListener(
        "mousemove",
        handleMouseMove
      );
      document.documentElement.removeEventListener("mouseup", handleMouseUp);
      const width = window.innerWidth - resizeDiv.getBoundingClientRect().left;
      el.style.width = `${width}px`;
      resizeDiv.style.left = `0px`;
      document.body.style.userSelect = "";
      resizeDiv.style.backgroundColor = "";
    };

    resizeDiv.addEventListener("mousedown", handleMouseDown);

    // Clean up the event listeners when the directive is unbound
    resizeDiv._resizeDirectiveCleanup = () => {
      resizeDiv.removeEventListener("mousedown", handleMouseDown);
    };
  },

  unbind(el) {
    // Clean up the event listeners when the directive is unbound
    if (el._resizeDirectiveCleanup) {
      el._resizeDirectiveCleanup();
      delete el._resizeDirectiveCleanup;
    }
  },
};
