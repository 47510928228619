export default {
  PRODUCTS_LIST: "Liste des produits",
  ADD_PRODUCT: "Ajouter un produit",
  PRODUCT_ADDED: "Produit ajouté",
  EDIT_PRODUCT: "Modifier un produit",
  PRODUCT_UPDATED: "Produit mis à jour",
  DELETE_THIS_PRODUCT: "Supprimer ce produit ?",
  PRODUCT_DELETED: "Produit supprimé",
  SKU: "Sku",
  DELETE_THIS_SUPPLIER_PRODUCT: "Supprimer ce produit?",
  DELETE_THIS_WAREHOUSE_PRODUCT: "Supprimer ce produit?",
  PRODUCTS_SAVED: "Produits enregistrés",
  ADD_SUPPLIER: "Ajouter un fournisseur",
  VIEW_PRODUCT: "Produits",
};
