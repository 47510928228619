import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/me?include=roles,roles.permissions,organization,reseller,allowedLocations`,
      options
    )
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function update(profile) {
  const payload = jsona.serialize({
    stuff: profile,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/me?include=roles,roles.permissions`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function twoFaRequest(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/me/two-fa-request`, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function activate2FA(data) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/me/two-fa/activation/${data?.token}`, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function deactivate2FA() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/me/two-fa/deactivation`, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

export default {
  get,
  update,
  deactivate2FA,
  twoFaRequest,
  activate2FA,
};
