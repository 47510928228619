export default {
  TEMPLATES_MAILS_LIST: "Liste des modèles mails",
  ADD_TEMPLATE_MAIL: "Ajouter un modèle mail",
  TEMPLATE_MAIL_ADDED: "Modèle mail ajouté",
  EDIT_TEMPLATE_MAIL: "Modifier un modèle mail",
  TEMPLATE_MAIL_UPDATED: "Modèle mail mise à jour",
  DELETE_THIS_TEMPLATE_MAIL: "Supprimer ce modèle mail ?",
  TEMPLATE_MAIL_DELETED: "Modèle mail supprimée",
  SUBJECT: "Objet",
  TITLE: "Titre",
  FROM : "De",
  TO : "À",
  CC : "Cc",
  BCC : "Cci",
  TYPE : "Type",
  CONTENT: "Contenu",
  VIEW_TEMPLATE_MAIL: "Modèle mail",
};
