import permissions from "../constants/permissions";
import PurchasesModuleLayout from "@/views/Pages/PurchasesModule/PurchasesModuleLayout.vue";
import PurchasesModuleMenu from "@/views/Pages/PurchasesModule/PurchasesModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListOrderPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesOrderManagement/ListPurchasesOrderPage.vue"
  );

const ListInvoicePage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesInvoiceManagement/ListPurchasesInvoicePage.vue"
  );

const ListDeliveryPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesDeliveryManagement/ListPurchasesDeliveryPage.vue"
  );

const ListPaymentPage = () =>
  import(
    "@/views/Pages/PurchasesModule/PurchasesPaymentManagement/ListPurchasesPaymentPage.vue"
  );

const ListSupplierPage = () =>
  import(
    "@/views/Pages/PurchasesModule/SupplierManagement/ListSupplierPage.vue"
  );

const PurchasesPage = () =>
  import("@/views/Pages/PurchasesModule/PurchasesPage.vue");

let orderManagementPages = {
  path: "orders",
  components: {
    default: PurchasesModuleLayout,
    SidebarSecondLevelMenu: PurchasesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List PurchasesOrders",
      components: { default: ListOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_ORDERS,
        hasLevel2: true,
      },
    },
  ],
};

let invoiceManagementPages = {
  path: "invoices",
  components: {
    default: PurchasesModuleLayout,
    SidebarSecondLevelMenu: PurchasesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List PurchasesInvoices",
      components: { default: ListInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_INVOICES,
        hasLevel2: true,
      },
    },
  ],
};

let deliveryManagementPages = {
  path: "deliveries",
  components: {
    default: PurchasesModuleLayout,
    SidebarSecondLevelMenu: PurchasesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List PurchasesDeliveries",
      components: { default: ListDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_DELIVERIES,
        hasLevel2: true,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "payments",
  components: {
    default: PurchasesModuleLayout,
    SidebarSecondLevelMenu: PurchasesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List PurchasesPayments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PURCHASES_DELIVERIES,
        hasLevel2: true,
      },
    },
  ],
};

let suppliersManagementPages = {
  path: "suppliers",
  components: {
    default: PurchasesModuleLayout,
    SidebarSecondLevelMenu: PurchasesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Suppliers",
      components: { default: ListSupplierPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SUPPLIERS,
        hasLevel2: true,
      },
    },
  ],
};

const purchasesModuleRoutes = [
  {
    path: "",
    components: {
      default: PurchasesModuleLayout,
      SidebarSecondLevelMenu: PurchasesModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Purchases Page",
        components: { default: PurchasesPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  orderManagementPages,
  invoiceManagementPages,
  deliveryManagementPages,
  paymentManagementPages,
  suppliersManagementPages,
];

export default purchasesModuleRoutes;
