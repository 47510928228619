<template>
  <span>{{ total }}</span>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},

  props: {},

  async created() {
    this.getNotifications();

    this.unwatch = this.$store.watch(
      (state, getters) => getters["notifications/refreshKey"],
      (newValue, oldValue) => {
        if (newValue > oldValue) {
          this.getNotifications();
        }
      }
    );
  },

  destroyed() {
    clearTimeout(this.notificationTimeout);
  },

  computed: mapState(["notifications/list"]),

  data() {
    return {
      total: 0,
      notifications: [],
      loading: true,
      notificationTimeout: null,
      unwatch: null,
    };
  },

  methods: {
    async getNotifications() {
      clearTimeout(this.notificationTimeout);
      try {
        let params = {
          ...(this.created_at ? { sort: this.created_at } : {}),
          filter: { unRead: true },
          page: {
            number: 1,
            size: 10,
          },
          include: "notifiable",
        };
        await this.$store.dispatch("notifications/list", params);
        this.notifications = this.$store.getters["notifications/list"];
        this.total = this.$store.getters["notifications/listTotal"];
        this.loading = false;
        this.notificationTimeout = setTimeout(() => {
          this.getNotifications();
        }, 60000);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
