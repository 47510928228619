export default {
  STOCK_MOVEMENTS_LIST: "Liste des mouvement de stocks",
  ADD_STOCK_MOVEMENT: "Ajouter un mouvement de stock",
  ADD_STOCK_MOVEMENT_ITEM: "Ajouter une ligne",
  STOCK_MOVEMENT_ADDED: "Mouvement de stock ajouté",
  EDIT_STOCK_MOVEMENT: "Modifier un mouvement de stock",
  STOCK_MOVEMENT_UPDATED: "Mouvement de stock mis à jour",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  DELETE_STOCK_MOVEMENT_ITEM: "Supprimer la ligne",
  UPDATE_STOCK_MOVEMENT_ITEM: "Modifier la ligne",
  STOCK_MOVEMENT_ITEM_DELETED: "Ligne supprimée",
  STOCK_MOVEMENT_ITEM_ADDED: "Ligne ajoutée",
  STOCK_MOVEMENT_ITEM_EDITED: "Ligne modifiée",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  TYPE_ADD: "Ajouter",
  TYPE_REMOVE: "Retirer",
  TYPE_MOVE: "Deplacer",
  TYPE_CORRECT: "Corriger",
  VALIDATE: "Valider",
  CANCEL: "Annuler",
  STOCK_MOVEMENT_VALIDATED: "Mouvement de stock validé",
  STOCK_MOVEMENT_CANCELED: "Mouvement de stock annulé",
  CANCEL_STOCK_MOVEMENT_QUESTION: "Annuler un mouvement de stock?",
  VIEW_STOCK_MOVEMENT: "Mouvement de stock",
  DELETE_THIS_STOCK_MOVEMENT_PRODUCT: "Supprimer cette ligne?",
  DELETE_THIS_STOCK_MOVEMENT: "Supprimer ce movement de stock?",
  STATUS_DRAFT: "Brouillon",
  STATUS_CANCELED: "Annulé",
  STATUS_VALIDATED: "Validé",
};
