export default {
  WAREHOUSES_LIST: "Liste des entrepôts",
  ADD_WAREHOUSE: "Ajouter un entrepôt",
  WAREHOUSE_ADDED: "Entrepôt ajouté",
  EDIT_WAREHOUSE: "Modifier un entrepôt",
  WAREHOUSE_UPDATED: "Entrepôt mis à jour",
  DELETE_THIS_WAREHOUSE: "Supprimer ce entrepôt ?",
  WAREHOUSE_DELETED: "Entrepôt supprimé",
  ALLOW_NEGATIVE_INVENTORY: "Autoriser un stock negatif",
  ALLOW_UNREGISTERED_PRODUCTS: "Autoriser les produits non enregistrés",
  VIEW_WAREHOUSE: "Entrepôt",
};
