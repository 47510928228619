const currencySymbol = process.env.VUE_APP_DEFAULT_CURRENCY_SYMBOL;

const formatCurrency = {
  install: (Vue) => {
    Vue.prototype.$formatCurrency = (value) => {
      if (value === null) {
        return "";
      }
      return `${Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      }).format(value)}`;
    };
  },
};

export default formatCurrency;
