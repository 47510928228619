export const SOURCE_SOURCE_BANK_DRAFT = 'BANK_DRAFT';
export const SOURCE_CERTIFIED_CHECK = 'CERTIFIED_CHECK';
export const SOURCE_POST_ORDER = 'POST_ORDER';
export const SOURCE_CASH = 'CASH';
export const SOURCE_BANK_TRANSFER = 'BANK_TRANSFER';
export const SOURCE_NORMAL_CHECK = 'NORMAL_CHECK';
export const SOURCE_FIDEOCOMMIS_CHECK = 'FIDEOCOMMIS_CHECK';
export const SOURCE_PAYMENT_SOURCE_NORMAL_CHECK = 'PAYMENT_SOURCE_NORMAL_CHECK';

export const PROPERTIES_PAYMENTS_SOURCES = [
  SOURCE_SOURCE_BANK_DRAFT,
  SOURCE_CERTIFIED_CHECK,
  SOURCE_POST_ORDER,
  SOURCE_CASH,
  SOURCE_BANK_TRANSFER,
  SOURCE_NORMAL_CHECK,
  SOURCE_FIDEOCOMMIS_CHECK,
  SOURCE_PAYMENT_SOURCE_NORMAL_CHECK,
];

export const PAYMENT_STATUS_DRAFT = "DRAFT";
export const PAYMENT_STATUS_COMPLETED = "COMPLETED";
export const PAYMENT_STATUS_CANCELED = "CANCELED";

export const PROPERTIES_PAYMENTS_STATUS = [
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_CANCELED,
];