export default {
  SOMETHING_WENT_WRONG: "Une erreur est survenue",
  PAGE_NOT_ALLOWED: "Vous n'êtes pas autorisé à accéder à cette page",
  UNAUTHORIZED_ACTION: "Vous n'êtes pas autorisé à faire cette action",
  FILE_UPLOAD_ERROR: "Erreur lors du chargement du fichier",
  NO_PROPERTY_FOUND: "AUCUNE PROPRIÉTÉ AU STATUT CONFIRMÉ TROUVÉE",
  NO_SOLD_PROPERTY_FOUND: "AUCUNE PROPRIÉTÉ VENDUE TROUVÉE",
  NO_PAID_PROPERTY_FOUND: "AUCUNE PROPRIÉTÉ PAYÉE AVANT LA VENTE TROUVÉE",
  ALL_FIELDS_REQUIRED: "TOUS LES CHAMPS SONT REQUIS",
  TEMPLATES_REQUIRED: "MODÈLES DE CONTENUS POUR LA LETTRE NON DÉFINIS",
  NO_PROPERTY_SELECTED: "Veuillez ajouter au moins une propriété avec un montant",
  EMPTY_AMOUNT_PROPERTY: "Veuillez remplir les montants des propriétés",
  PROPERTIES_AMOUNTS_PAYMENT: "la somme des montants saisis pour les propriétés doit étre égale au montant du paiement",
};
