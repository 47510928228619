export default {
  CONTACTS_LIST: "Liste des contacts",
  ADD_CONTACT: "Ajouter un contact",
  CONTACTS: "Personnes ressources",
  CONTACT_ADDED: "Contact ajouté",
  EDIT_CONTACT: "Modifier un contact",
  CONTACT_UPDATED: "Contact mise à jour",
  DELETE_THIS_CONTACT: "Supprimer ce contact ?",
  CONTACT_DELETED: "Contact supprimé",
  MISTER: "M.",
  MADAM: "Mme",
  CONTACT_TYPE: "Type de contact",
  TITLE_MR: "M.",
  TITLE_MME: "Mme",
  VIEW_CONTACT: "Contact",
  NO_ADDRESS_MESSAGE:
    "Ce contact n'a pas d'adresse. Voulez-vous l'enregistrer avec l'adresse suivante par défaut?",
  ADDRESS_CONFIRMATION: "Confirmation d'adresse",
  PROPERTIES: "Propriétés",
  USE_DEFAULT_ADDRESS: "Utiliser l’adresse par défaut",
  WITHOUT_ADDRESS: "Ne pas mettre d’adresse",
  RETURN_TO_FROM: "Retourner au formulaire",
  SAME_CONTACTS:
    'Un ou plusieurs contacts ont déjà été enregistrés avec le prénom {firstname} et le nom {lastname}. S\'agit-il de la même personne ? Cliquez sur "Continuer" pour enregistrer si c\'est le cas, sinon cliquez sur "Annuler".',
};
