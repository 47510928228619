import { QUERY_ACTIONS_VIEW } from "../constants/common";

const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};
const objectRoute = {
  install: (Vue) => {
    Vue.prototype.$objectViewRoute = (object) => {
      if (object) {
        if (object.type) {
          if (object.type.split("-").length > 1) {
            const objectRouteName = object.type
              .split("-")
              .map((item) => capitalizeFirstLetter(item))
              .join("");
            return {
              name: `List ${objectRouteName}`,
              query: { id: object.id, action: QUERY_ACTIONS_VIEW },
            };
          }
          return {
            name: `List ${capitalizeFirstLetter(object.type)}`,
            query: { id: object.id, action: QUERY_ACTIONS_VIEW },
          };
        }
      }
      return "/";
    };
  },
};

export default objectRoute;
