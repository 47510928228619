<template>
  <div class="elite-content_sidebar_level2">
    <div class="elite-content_sidebar_level2_inner">
      <h2>{{ $t("APPS.PURCHASES_MODULE") }}</h2>
      <ul class="sub-menu-items">
        <li v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_SUPPLIERS)">
          <router-link
            :to="{ name: 'List Suppliers' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.SUPPLIERS") }}
              </span>
            </a>
          </router-link>
        </li>

        <li
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_ORDERS)"
        >
          <router-link
            :to="{ name: 'List PurchasesOrders' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.PURCHASES_ORDERS") }}
              </span>
            </a>
          </router-link>
        </li>
        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_INVOICES)
          "
        >
          <router-link
            :to="{ name: 'List PurchasesInvoices' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.PURCHASES_INVOICES") }}
              </span>
            </a>
          </router-link>
        </li>
        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_PAYMENTS)
          "
        >
          <router-link
            :to="{ name: 'List PurchasesPayments' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.PURCHASES_PAYMENTS") }}
              </span>
            </a>
          </router-link>
        </li>
        <li
          v-if="
            $currentUserCan($permissions.PERM_VIEW_MODULE_PURCHASES_DELIVERIES)
          "
        >
          <router-link
            :to="{ name: 'List PurchasesDeliveries' }"
            v-slot="{ href, navigate, isActive }"
            custom
          >
            <a
              :href="href"
              @click="navigate"
              :class="{ 'active-link': isActive }"
            >
              <span class="text">
                {{ $t("COMMON.PURCHASES_DELIVERIES") }}
              </span>
            </a>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moduleLayoutMixin from "@/mixins/module-layout-mixin";

export default {
  components: {},

  mixins: [moduleLayoutMixin],

  data() {
    return {};
  },

  methods: {},

  async mounted() {},

  computed: {},

  watch: {},
};
</script>
