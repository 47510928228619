<template>
  <div>
    <span v-for="(location, idx) in locations" :key="location.id">
      <router-link :to="$objectViewRoute(location)">
        <template>
          {{ `${location.name}` }}
          {{ locations.length - 1 > idx ? "," : " " }}<br />
        </template>
      </router-link>
    </span>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "locations",

  components: {},

  mixins: [formMixin],

  props: {
    locations: {
      type: Array,
      default: () => [],
      description: "Locations",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    locations(locations) {},
  },
};
</script>
