import moment from "moment-timezone";

const utcDatePlugin = {
  install: (Vue) => {
    Vue.prototype.$convertDateToUtc = (value) => {
      const userTimezone = moment.tz.guess();
      const momentDate = moment.tz(value, userTimezone).utc();
      return momentDate.toISOString();
    };
  },
};

export default utcDatePlugin;
