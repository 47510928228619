import {
  TYPE_FACEBOOK,
  TYPE_LINKEDIN,
  TYPE_HUISSIERS,
  TYPE_411,
  TYPE_TELEPHONE,
  TYPE_COURRIEL,
  EXPEDITION_POSTE,
} from "../../constants/communications";
export default {
  COMMUNICATIONS_LIST: "Liste des communications",
  ADD_COMMUNICATION: "Ajouter une communication",
  COMMUNICATION_ADDED: "Communication ajouté",
  EDIT_COMMUNICATION: "Modifier une communication",
  COMMUNICATION_UPDATED: "Communication mise à jour",
  DELETE_THIS_COMMUNICATION: "Supprimer cette communication ?",
  COMMUNICATION_DELETED: "Communication supprimée",
  COMMUNICATION_TYPE: "Type de communication",
  TRAKING_NUMBER: "Numéro de suivi",
  DATE : "Date de la communication",
  EXCERPT: "Note",
  ATTACHEMENTS: "Pieces jointes",
  VIEW_COMMUNICATION: "Communication",
  ADD_PROPERTY: "Ajouter une propriété",
  COMMUNICATIONS_TYPE_FACEBOOK : "Facebook",
  COMMUNICATIONS_TYPE_LINKEDIN : "LinkedIn",
  COMMUNICATIONS_TYPE_HUISSIERS : "Huissiers",
  COMMUNICATIONS_TYPE_411 : "411",
  COMMUNICATIONS_TYPE_TELEPHONE : "Téléphone",
  COMMUNICATIONS_TYPE_COURRIEL : "Courriel",
  COMMUNICATIONS_TYPE_EXPEDITION_POSTE : "Expédition par la poste",
  CONTACT: "Contact"
};
