import permissions from "../constants/permissions";
import SalesModuleLayout from "@/views/Pages/SalesModule/SalesModuleLayout.vue";
import SalesModuleMenu from "@/views/Pages/SalesModule/SalesModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/ListSalesOrderPage.vue"
  );

const ListInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/ListSalesInvoicePage.vue"
  );

const ListDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/ListSalesDeliveryPage.vue"
  );

const ListPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/ListSalesPaymentPage.vue"
  );

const SalesPage = () => import("@/views/Pages/SalesModule/SalesPage.vue");

let orderManagementPages = {
  path: "orders",
  components: {
    default: SalesModuleLayout,
    SidebarSecondLevelMenu: SalesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SalesOrders",
      components: { default: ListOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_ORDERS,
        hasLevel2: true,
      },
    },
  ],
};

let invoiceManagementPages = {
  path: "invoices",
  components: {
    default: SalesModuleLayout,
    SidebarSecondLevelMenu: SalesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SalesInvoices",
      components: { default: ListInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_INVOICES,
        hasLevel2: true,
      },
    },
  ],
};

let deliveryManagementPages = {
  path: "deliveries",
  components: {
    default: SalesModuleLayout,
    SidebarSecondLevelMenu: SalesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SalesDeliveries",
      components: { default: ListDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_DELIVERIES,
        hasLevel2: true,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "payments",
  components: {
    default: SalesModuleLayout,
    SidebarSecondLevelMenu: SalesModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List SalesPayments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_DELIVERIES,
        hasLevel2: true,
      },
    },
  ],
};

const salesModuleRoutes = [
  {
    path: "",
    components: {
      default: SalesModuleLayout,
      SidebarSecondLevelMenu: SalesModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Sales Page",
        components: { default: SalesPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  orderManagementPages,
  invoiceManagementPages,
  deliveryManagementPages,
  paymentManagementPages,
];

export default salesModuleRoutes;
