import axios from "axios";
const url = process.env.VUE_APP_API_BASE_URL;

const actions = {
  async forgotPassword({ commit }, data) {
    await axios.post(`${url}/password-forgot`, { data });
  },
  async createNewPassword({ commit }, data) {
    await axios.post(`${url}/password-reset`, { data });
  },
};

const reset = {
  namespaced: true,
  actions,
};

export default reset;
