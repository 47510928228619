import permissions from "../constants/permissions";
import CrmModuleLayout from "@/views/Pages/CrmModule/CrmModuleLayout.vue";
import CrmModuleMenu from "@/views/Pages/CrmModule/CrmModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListCustomerPage = () =>
  import("@/views/Pages/CrmModule/CustomerManagement/ListCustomerPage.vue");

const ListContactPage = () =>
  import("@/views/Pages/CrmModule/ContactManagement/ListContactPage.vue");

const CrmPage = () => import("@/views/Pages/CrmModule/CrmPage.vue");

let customerManagementPages = {
  path: "customers",
  components: {
    default: CrmModuleLayout,
    SidebarSecondLevelMenu: CrmModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Customers",
      components: { default: ListCustomerPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
        hasLevel2: true,
      },
    },
  ],
};

let contactManagementPages = {
  path: "contacts",
  components: {
    default: CrmModuleLayout,
    SidebarSecondLevelMenu: CrmModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Contacts",
      components: { default: ListContactPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CONTACTS,
        hasLevel2: true,
      },
    },
  ],
};

const crmModuleRoutes = [
  {
    path: "",
    components: {
      default: CrmModuleLayout,
      SidebarSecondLevelMenu: CrmModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Crm Page",
        components: { default: CrmPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  customerManagementPages,
  contactManagementPages,
];

export default crmModuleRoutes;
