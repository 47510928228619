<template>
  <router-link :to="$objectViewRoute(object)">
    {{ $objectDenomination(object) }}
  </router-link>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "object-link",

  components: {},

  mixins: [formMixin],

  props: {
    object: {
      type: Object,
      default: () => ({}),
      description: "Object",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    object(object) {},
  },
};
</script>
