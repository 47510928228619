import permissions from "../constants/permissions";
import InventoryModuleLayout from "@/views/Pages/InventoryModule/InventoryModuleLayout.vue";
import InventoryModuleMenu from "@/views/Pages/InventoryModule/InventoryModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/ListProductPage.vue");

const ListWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/ListWarehousePage.vue"
  );

const ListStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/ListStockMovementPage.vue"
  );

const InventoryPage = () =>
  import("@/views/Pages/InventoryModule/InventoryPage.vue");

let productManagementPages = {
  path: "products",
  components: {
    default: InventoryModuleLayout,
    SidebarSecondLevelMenu: InventoryModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Products",
      components: { default: ListProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
        hasLevel2: true,
      },
    },
  ],
};

let warehouseManagementPages = {
  path: "warehouses",
  components: {
    default: InventoryModuleLayout,
    SidebarSecondLevelMenu: InventoryModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Warehouses",
      components: { default: ListWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_WAREHOUSES,
        hasLevel2: true,
      },
    },
  ],
};

let stockMovementManagementPages = {
  path: "stock-movements",
  components: {
    default: InventoryModuleLayout,
    SidebarSecondLevelMenu: InventoryModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List StockMovements",
      components: { default: ListStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_STOCK_MOVEMENTS,
        hasLevel2: true,
      },
    },
  ],
};

const inventoryModuleRoutes = [
  {
    path: "",
    components: {
      default: InventoryModuleLayout,
      SidebarSecondLevelMenu: InventoryModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Inventory Page",
        components: { default: InventoryPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  productManagementPages,
  warehouseManagementPages,
  stockMovementManagementPages,
];

export default inventoryModuleRoutes;
