<template>
  <span v-if="phoneNumber">
    {{ type ? $t(`COMMON.PHONE_TYPE_${type}`) : null }}
    {{ formattedPhone }}
    {{ extension ? ` (ext: ${extension})` : null }}
  </span>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import { formatNumber, parseNumber } from "libphonenumber-js";

export default {
  name: "phone-number",

  components: {},

  mixins: [formMixin],

  props: {
    phoneNumber: {
      type: String,
      default: null,
      description: "Phone number",
    },
    extension: {
      type: String,
      default: null,
      description: "Phone extension",
    },
    type: {
      type: String,
      default: null,
      description: "Phone type",
    },
  },

  data() {
    return {};
  },

  computed: {
    formattedPhone() {
      try {
        return formatNumber(parseNumber(this.phoneNumber), "NATIONAL");
      } catch (e) {
        return this.phoneNumber;
      }
    },
  },

  setup() {},

  created() {},

  methods: {},
};
</script>
