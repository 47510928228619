var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-content_sidebar_level2"},[_c('div',{staticClass:"elite-content_sidebar_level2_inner"},[_c('h2',[_vm._v(_vm._s(_vm.$t("APPS.RESELLER_MODULE")))]),_c('ul',{staticClass:"sub-menu-items"},[(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_INVOICES)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List ResellerInvoices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RESELLER_INVOICES"))+" ")])])]}}],null,false,571119819)})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_PAYMENTS)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List ResellerPayments' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RESELLER_PAYMENTS"))+" ")])])]}}],null,false,1702319200)})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUBSCRIPTIONS))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List Subscriptions' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.SUBSCRIPTIONS"))+" ")])])]}}],null,false,2548169488)})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_PRODUCTS)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List ResellerProducts' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RESELLER_PRODUCTS"))+" ")])])]}}],null,false,1548906673)})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_RESELLER_SERVICES)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List ResellerServices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.RESELLER_SERVICES"))+" ")])])]}}],null,false,4128840391)})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PACKAGES))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List Packages' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PACKAGES"))+" ")])])]}}],null,false,170450089)})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }