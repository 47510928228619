const numberFormat = {
  install: (Vue) => {
    Vue.prototype.$numberFormat = (value) => {
      value = value.toString().replaceAll(" ", "");
      if (isNaN(value)) {
        return "0 000 000";
      }
      return `${Intl.NumberFormat("fr", {
        style: "decimal",
        maximumFractionDigits: 0,
      }).format(value)}`;
    };
  },
};

export default numberFormat;
