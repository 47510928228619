var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-content_sidebar_level2"},[_c('div',{staticClass:"elite-content_sidebar_level2_inner"},[_c('h2',[_vm._v(_vm._s(_vm.$t("APPS.PURCHASES_MODULE")))]),_c('ul',{staticClass:"sub-menu-items"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_SUPPLIERS))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List Suppliers' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.SUPPLIERS"))+" ")])])]}}],null,false,3714506791)})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_ORDERS))?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List PurchasesOrders' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PURCHASES_ORDERS"))+" ")])])]}}],null,false,4257703962)})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_INVOICES)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List PurchasesInvoices' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PURCHASES_INVOICES"))+" ")])])]}}],null,false,2490745125)})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_PAYMENTS)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List PurchasesPayments' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PURCHASES_PAYMENTS"))+" ")])])]}}],null,false,2484497518)})],1):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_MODULE_PURCHASES_DELIVERIES)
        )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'List PurchasesDeliveries' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive }){return [_c('a',{class:{ 'active-link': isActive },attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.PURCHASES_DELIVERIES"))+" ")])])]}}],null,false,2020261117)})],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }