export default {
  SALES_PAYMENTS_LIST: "Liste des paiements",
  ADD_SALES_PAYMENT: "Ajouter un paiement",
  SALES_PAYMENT_ADDED: "Paiement ajouté",
  AUTHORIZATIONS: "Autorisations",
  EDIT_SALES_PAYMENT: "Modifier le paiement",
  SALES_PAYMENT_SOURCE_MANUAL: "Manuel",
  SALES_PAYMENT_SOURCE_PAYPAL: "Paypal",
  SALES_PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Id transaction",
  TRANSACTION_DATA: "Données transaction",
  SOURCE_DETAILS: "Détails du paiement",
  VIEW_INVOICE: "Voir la facture",
  VIEW_SALES_PAYMENT: "Paiement",
};
