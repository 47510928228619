export default {
  PURCHASES_DELIVERIES_LIST: "Liste des livraisons",
  ADD_PURCHASES_DELIVERY: "Ajouter une livraison",
  ADD_PURCHASES_DELIVERY_ITEM: "Ajouter une ligne",
  PURCHASES_DELIVERY_ADDED: "Livraison ajoutée",
  OWNER: "Propriétaire",
  EDIT_PURCHASES_DELIVERY: "Modifier une livraison",
  PURCHASES_DELIVERY_UPDATED: "Livraison mise à jour",
  BILLING_ADDRESS: "Adresse de facturation",
  EXPIRATION_TIME: "Date d'expiration",
  ITEM_TYPE: "Type",
  SELECT_PRODUCTS: "Choisir un produit",
  SELECT_SUBSCRIPTIONS: "Choisir une inscription",
  SELECT_PACKAGES: "Choisir un forfait",
  DELETE_PURCHASES_DELIVERY_ITEM: "Supprimer la ligne",
  UPDATE_PURCHASES_DELIVERY_ITEM: "Modifier la ligne",
  PURCHASES_DELIVERY_ITEM_DELETED: "Ligne supprimée",
  PURCHASES_DELIVERY_ITEM_ADDED: "Ligne ajoutée",
  PURCHASES_DELIVERY_ITEM_EDITED: "Ligne modifiée",
  SEND: "Envoyer",
  CANCEL: "Annuler",
  REFUND: "Rembourser",
  ADD_PAYMENT: "Ajouter un paiement",
  PURCHASES_DELIVERY_SENT: "Livraison envoyée",
  SELECT_USERS: "Choisir l'utilisateur",
  SELECT_CUSTOMERS: "Choisir le client",
  SELECT_ORGANIZATIONS: "Choisir l'organization",
  SELECT_SUPPLIERS: "Choisir le fournisseur",
  DELETE_THIS_PURCHASES_DELIVERY: "Supprimer cette livraison ?",
  PURCHASES_DELIVERY_DELETED: "Livraison supprimée",
  CANCEL_PURCHASES_DELIVERY_QUESTION: "Annuler la livraison ?",
  PURCHASES_DELIVERY_CANCELED: "Livraison annulée",
  RECIPIENT: "Destinataire",
  ISSUER: "Emetteur",
  VALIDATE: "Valider",
  PURCHASES_DELIVERY_VALIDATED: "Livraison validée",
  VIEW_ORDER: "Voir la commande",
  VIEW_PURCHASES_DELIVERY: "Livraison achat",
  VALIDATE_DELIVERY: "Valider la livraison",
  PURCHASES_ORDER_DELIVERY_ADDED: "Livraison ajoutée",
};
