import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const jsona = new Jsona();
const url = process.env.VUE_APP_API_BASE_URL;

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/comments`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  const includes = ["organization", "object", "owner", "replyTo"];

  return axios
    .get(`${url}/comments/${id}?include=${includes.join(",")}`, options)
    .then((response) => {
      let customer = jsona.deserialize(response.data);
      delete customer.links;
      return customer;
    });
}

function add(comment) {
  const payload = jsona.serialize({
    stuff: comment,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/comments`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(comment) {
  const payload = jsona.serialize({
    stuff: comment,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/comments/${comment.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/comments/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
