import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/users`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/users/${id}?include=roles,roles.permissions,reseller,organization,allowedLocations`,
      options
    )
    .then((response) => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

function add(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/users?include=roles`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

async function code_verification(user) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return await axios.post(`${url}/verify`, user, options).then((response) => {
    return response?.data;
  });
}

function update(user) {
  const payload = jsona.serialize({
    stuff: user,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/users/${user.id}?include=roles`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/users/${id}`, options);
}

function activate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/users/${id}/activate?include=roles,roles.permissions,reseller,organization`,
      options
    )
    .then((response) => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

function deactivate(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(
      `${url}/users/${id}/deactivate?include=roles,roles.permissions,reseller,organization`,
      options
    )
    .then((response) => {
      let user = jsona.deserialize(response.data);
      delete user.links;
      return user;
    });
}

export default {
  list,
  get,
  add,
  update,
  destroy,
  activate,
  deactivate,
  code_verification,
};
