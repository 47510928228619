import permissions from "../constants/permissions";
import ResellerModuleLayout from "@/views/Pages/ResellerModule/ResellerModuleLayout.vue";
import ResellerModuleMenu from "@/views/Pages/ResellerModule/ResellerModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListResellerInvoicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerInvoiceManagement/ListResellerInvoicePage.vue"
  );

const ListResellerPaymentPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerPaymentManagement/ListResellerPaymentPage.vue"
  );

const ListResellerProductPage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerProductManagement/ListResellerProductPage.vue"
  );

const ListResellerServicePage = () =>
  import(
    "@/views/Pages/ResellerModule/ResellerServiceManagement/ListResellerServicePage.vue"
  );

const ListPackagePage = () =>
  import("@/views/Pages/ResellerModule/PackageManagement/ListPackagePage.vue");

const ListSubscriptionPage = () =>
  import(
    "@/views/Pages/ResellerModule/SubscriptionManagement/ListSubscriptionPage.vue"
  );

const ResellerPage = () =>
  import("@/views/Pages/ResellerModule/ResellerPage.vue");

let resellerInvoiceManagementPages = {
  path: "reseller-invoices",
  components: {
    default: ResellerModuleLayout,
    SidebarSecondLevelMenu: ResellerModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List ResellerInvoices",
      components: { default: ListResellerInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_INVOICES,
        hasLevel2: true,
      },
    },
  ],
};

let resellerPaymentManagementPages = {
  path: "reseller-payments",
  components: {
    default: ResellerModuleLayout,
    SidebarSecondLevelMenu: ResellerModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List ResellerPayments",
      components: { default: ListResellerPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_PAYMENTS,
        hasLevel2: true,
      },
    },
  ],
};

let resellerProductsManagementPages = {
  path: "reseller-products",
  components: {
    default: ResellerModuleLayout,
    SidebarSecondLevelMenu: ResellerModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List ResellerProducts",
      components: { default: ListResellerProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_PRODUCTS,
        hasLevel2: true,
      },
    },
  ],
};

let resellerServicesManagementPages = {
  path: "reseller-services",
  components: {
    default: ResellerModuleLayout,
    SidebarSecondLevelMenu: ResellerModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List ResellerServices",
      components: { default: ListResellerServicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_RESELLER_SERVICES,
        hasLevel2: true,
      },
    },
  ],
};

let packageManagementPages = {
  path: "packages",
  components: {
    default: ResellerModuleLayout,
    SidebarSecondLevelMenu: ResellerModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Packages",
      components: { default: ListPackagePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
        hasLevel2: true,
      },
    },
  ],
};

let subscriptionManagementPages = {
  path: "subscriptions",
  components: {
    default: ResellerModuleLayout,
    SidebarSecondLevelMenu: ResellerModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Subscriptions",
      components: { default: ListSubscriptionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CUSTOMERS,
        hasLevel2: true,
      },
    },
  ],
};

const resellerModuleRoutes = [
  {
    path: "",
    components: {
      default: ResellerModuleLayout,
      SidebarSecondLevelMenu: ResellerModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Reseller Page",
        components: { default: ResellerPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  resellerInvoiceManagementPages,
  resellerPaymentManagementPages,
  resellerProductsManagementPages,
  resellerServicesManagementPages,
  packageManagementPages,
  subscriptionManagementPages,
];

export default resellerModuleRoutes;
