import store from "../store";

const fillUserResellerData = {
  install: (Vue) => {
    Vue.prototype.$fillUserResellerData = (object) => {
      const me = store.getters["profile/me"];
      if (!!me.reseller?.id) {
        object.reseller.id = me.reseller.id;
      }
      return object;
    };
  },
};

export default fillUserResellerData;
