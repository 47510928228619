import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/notifications`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.get(`${url}/notifications/${id}`, options).then((response) => {
    let notification = jsona.deserialize(response.data);
    delete notification.links;
    return notification;
  });
}

function markAsRead(ids) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/notifications/mark-as-read`, ids, options)
    .then((response) => {
      return response;
    });
}

function markAsUnRead(ids) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/notifications/mark-as-unread`, ids, options)
    .then((response) => {
      return response;
    });
}

export default {
  list,
  get,
  markAsRead,
  markAsUnRead,
};
