export default {
  PROFILE_UPDATED: "Profil modifié",
  YOUR_SECURITY: "Votre sécurité",
  ENABLE_2FA: "Activez l'authentification à deux facteurs",
  ENABLE_2FA_AT: "Activé le",
  DISABLE_2FA: "Désactivez l'authentification à deux facteurs",
  DISABLE_2FA_AT: "Désactivé le",
  EMAIL_IS_SENT_TO_ENABLE_2FA:
    "Un e-mail d'activation pour la double authentification a été envoyé à votre adresse. Veuillez vérifier votre boîte de réception.",
  ENABLE_2FA_SUCCESS:
    "Félicitations ! Vous avez activé la vérification en deux étapes pour sécuriser davantage votre compte. Votre compte est maintenant plus sûr.",
  DISABLE_2FA_SUCCESS:
    "La vérification en deux étapes a été désactivée avec succès. Vous pouvez maintenant vous connecter sans saisir de code supplémentaire.",
};
