import {
  SOURCE_SOURCE_BANK_DRAFT,
  SOURCE_CERTIFIED_CHECK,
  SOURCE_POST_ORDER,
  SOURCE_CASH,
  SOURCE_BANK_TRANSFER,
  SOURCE_NORMAL_CHECK,
  SOURCE_FIDEOCOMMIS_CHECK,
  SOURCE_PAYMENT_SOURCE_NORMAL_CHECK,
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_CANCELED,
} from "../../constants/propertiesPayment";
export default {
  PROPERTIES_PAYMENTS_LIST: "Liste des paiements",
  ADD_PROPERTIES_PAYMENT: "Ajouter un paiement",
  PROPERTIES_PAYMENT_ADDED: "Paiement ajouté",
  EDIT_PROPERTIES_PAYMENT: "Modifier un paiement",
  PROPERTIES_PAYMENT_UPDATED: "Paiement mise à jour",
  PROPERTY: "Propriété",
  AMOUNT: "Montant",
  SOURCE: "Méthode de transaction",
  DATE: "Date de paiement",
  ATTENTION : "à l'attention de",
  EXCERPT: "Note",
  VIEW_PROPERTIES_PAYMENT: "Paiement",
  DELETE_THIS_PROPERTIES_PAYMENT: "Supprimer ce paiement ?",
  PROPERTIES_PAYMENT_DELETED: "Paiement supprimé",
  PAYMENT_SOURCE_BANK_DRAFT: "Traite bancaire",
  PAYMENT_SOURCE_CERTIFIED_CHECK: "Chèque certifié",
  PAYMENT_SOURCE_POST_ORDER: "Mandat poste",
  PAYMENT_SOURCE_CASH: "Argent comptant",
  PAYMENT_SOURCE_BANK_TRANSFER: "Transfert bancaire",
  PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normale",
  PAYMENT_SOURCE_FIDEOCOMMIS_CHECK: "Chèque fidéocommis",
  PAYMENT_SOURCE_PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normal",
  PAYMENT_STATUS_DRAFT: "Brouillon",
  PAYMENT_STATUS_COMPLETED: "Completée",
  PAYMENT_STATUS_CANCELED: "Annulée",
};
