export default {
  CUSTOMERS_LIST: "Liste des clients",
  ADD_CUSTOMER: "Ajouter un client",
  CUSTOMER_ADDED: "Client ajouté",
  EDIT_CUSTOMER: "Modifier un client",
  CUSTOMER_UPDATED: "Client mis à jour",
  DELETE_THIS_CUSTOMER: "Supprimer ce client ?",
  CUSTOMER_DELETED: "Client supprimé",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Même adresse que le client",
  CUSTOMER_TYPE_INDIVIDUAL: "Particulier",
  CUSTOMER_TYPE_COMPANY: "Entreprise",
  VIEW_CUSTOMER: "Client",
};
