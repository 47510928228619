export default (user, permission) => {
  if (!user) {
    return false;
  }
  if (!user.roles) {
    return false;
  }
  if (user.roles.length === 0) {
    return false;
  }
  for (const role of user.roles) {
    for (const vPermission of role.permissions) {
      if (vPermission.name === permission) {
        return true;
      }
    }
  }
  return false;
};
