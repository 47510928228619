import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios
    .get(`${url}/notification-subscriptions`, options)
    .then((response) => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta,
      };
    });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/notification-subscriptions/${id}`, options)
    .then((response) => {
      let tag = jsona.deserialize(response.data);
      delete tag.links;
      return tag;
    });
}

function add(tag) {
  const payload = jsona.serialize({
    stuff: tag,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/notification-subscriptions`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(tag) {
  const payload = jsona.serialize({
    stuff: tag,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/notification-subscriptions/${tag.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/notification-subscriptions/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
