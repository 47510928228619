import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/stock-movement-items`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/stock-movement-items/${id}?include=`, options)
    .then((response) => {
      let stockMovementItem = jsona.deserialize(response.data);
      delete stockMovementItem.links;
      return stockMovementItem;
    });
}

function add(stockMovementItem) {
  const payload = jsona.serialize({
    stuff: stockMovementItem,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/stock-movement-items?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(stockMovementItem) {
  const payload = jsona.serialize({
    stuff: stockMovementItem,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(
      `${url}/stock-movement-items/${stockMovementItem.id}?include=`,
      payload,
      options
    )
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/stock-movement-items/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
