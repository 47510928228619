import service from "@/store/services/imports-service";

export const state = () => ({
  list: {},
  import: {},
  models: {},
  meta: {},
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, data) => {
    state.import = data;
  },
  SET_MODEL: (state, data) => {
    state.models = data;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((data) => {
      commit("SET_RESOURCE", data);
    });
  },

  models({ commit, dispatch }, params) {
    return service.models(params, this.$axios).then((data) => {
      commit("SET_MODEL", data);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((data) => {
      commit("SET_RESOURCE", data);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((data) => {
      commit("SET_RESOURCE", data);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  runDry({ commit, dispatch }, params) {
    return service.runDry(params, this.$axios).then((data) => {
      commit("SET_RESOURCE", data);
      return data;
    });
  },

  run({ commit, dispatch }, params) {
    return service.run(params, this.$axios).then((data) => {
      commit("SET_RESOURCE", data);
      return data;
    });
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  import: (state) => state.import,
  models: (state) => state.models,
  dropdown: (state) => {
    return state.list.map((data) => ({
      id: data.id,
      name: data.name,
    }));
  },
};

const imports = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default imports;
