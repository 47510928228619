import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

    return axios.get(`${url}/other-fees`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/other-fees/${id}?include=auction`, options)
    .then((response) => {
      let auctionFee = jsona.deserialize(response.data);
      delete auctionFee.links;
      return auctionFee;
    });
}

function add(auctionFee) {
  const payload = jsona.serialize({
    stuff: auctionFee,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .post(`${url}/other-fees?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function update(auctionFee) {
  const payload = jsona.serialize({
    stuff: auctionFee,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/other-fees/${auctionFee.id}?include=`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/other-fees/${id}`, options);
}
export default {
  list,
  get,
  add,
  update,
  destroy,
};
