export default {
  BIDS: "Mises",
  BEST_OFFER: "Meilleure offre: ",
  ALLOWED_BID_STEP: "Bonds de mises autorisés: ",
  STARTING_BID_AMOUNT: "Mise à prix de départ: ",
  BID_FOR: "Miser pour",
  BID_USER: "Utilisateur",
  BID: "Offre",
  TAKE_BID: "Miser",
  LAST_UPDATE: "Dernière mise à jour",
  PLACE_BET: "Placer une mise",
  INFO: "Important",
  INFO_TEXT:
    "Assurez-vous de choisir l'utilisateur pour lequel vous miser. Vous pouver choisir de miser manuellement en plaçant une mise ou laisser le système miser pour vous en renseignant une mise maximale. Notez que les mises se font en tenant compte des bonds de mises autorisés.",
  MAX_BID: "Mise maximale",
  CANCEL_MAX_BID: "Annuler",
};
