import qs from "qs";
import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { encode: false });
    },
  };

  return axios.get(`${url}/types-mails`, options).then((response) => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta,
    };
  });
}

function get(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .get(`${url}/types-mails/${id}?include=organization`, options)
    .then((response) => {
      let product = jsona.deserialize(response.data);
      delete product.links;
      return product;
    });
}

function add(product) {
  const payload = jsona.serialize({
    stuff: product,
    includeNames: null,
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.post(`${url}/types-mails`, payload, options).then((response) => {
    return jsona.deserialize(response.data);
  });
}

function update(product) {
  const payload = jsona.serialize({
    stuff: product,
    includeNames: [],
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios
    .patch(`${url}/types-mails/${product.id}`, payload, options)
    .then((response) => {
      return jsona.deserialize(response.data);
    });
}

function destroy(id) {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json",
    },
  };

  return axios.delete(`${url}/types-mails/${id}`, options);
}

export default {
  list,
  get,
  add,
  update,
  destroy,
};
