import permissions from "../constants/permissions";
import AuctionsModuleLayout from "@/views/Pages/AuctionsModule/AuctionsModuleLayout.vue";
import AuctionsModuleMenu from "@/views/Pages/AuctionsModule/AuctionsModuleMenu.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const AuctionsPage = () =>
  import("@/views/Pages/AuctionsModule/AuctionsPage.vue");

const ListPropertyPage = () =>
  import(
    "@/views/Pages/AuctionsModule/PropertyManagement/ListPropertyPage.vue"
  );

const ListAuctionPage = () =>
  import("@/views/Pages/AuctionsModule/AuctionManagement/ListAuctionPage.vue");

const ListCommunicationPage = () =>
  import("@/views/Pages/AuctionsModule/CommunicationManagement/ListCommunicationPage.vue");

const ListPaymentPage = () =>
  import("@/views/Pages/AuctionsModule/PropertiesPaymentManagement/ListPropertiesPaymentPage.vue");

let propertyManagementPages = {
  path: "properties",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Properties",
      components: { default: ListPropertyPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PROPERTIES,
      },
    },
  ],
};

let communicationManagementPages = {
  path: "communications",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Communications",
      components: { default: ListCommunicationPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_COMMUNICATIONS,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "properties-payments",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List PropertiesPayments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PROPERTIES_PAYMENTS,
        hasLevel2: true,
      },
    },
  ],
};

let auctionManagementPages = {
  path: "auctions",
  components: {
    default: AuctionsModuleLayout,
    SidebarSecondLevelMenu: AuctionsModuleMenu,
  },
  children: [
    {
      path: "",
      name: "List Auctions",
      components: { default: ListAuctionPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_AUCTIONS,
      },
    },
  ],
};

const AuctionModuleRoutes = [
  {
    path: "",
    components: {
      default: AuctionsModuleLayout,
      SidebarSecondLevelMenu: AuctionsModuleMenu,
    },
    children: [
      {
        path: "",
        name: "Auctions Page",
        components: { default: AuctionsPage },
        meta: {
          middleware: authenticated,
          hasLevel2: true,
        },
      },
    ],
  },
  propertyManagementPages,
  auctionManagementPages,
  communicationManagementPages,
  paymentManagementPages,
];

export default AuctionModuleRoutes;
