import service from "@/store/services/properties-service";

export const state = () => ({
  list: {},
  registrationDivisions: {},
  property: {},
  meta: {},
  url: null,
});

export const mutations = {
  SET_LIST: (state, list) => {
    state.list = list;
  },
  SET_RESOURCE: (state, property) => {
    state.property = property;
  },
  SET_META: (state, meta) => {
    state.meta = meta;
  },
  SET_URL: (state, url) => {
    state.url = url;
  },
  SET_REGISTRATIONS: (state, registrationDivisions) => {
    state.registrationDivisions = registrationDivisions;
  },
};

export const actions = {
  list({ commit, dispatch }, params = {}) {
    return service.list(params, this.$axios).then(({ list, meta }) => {
      commit("SET_LIST", list);
      commit("SET_META", meta);
    });
  },

  get({ commit, dispatch }, params) {
    return service.get(params, this.$axios).then((property) => {
      commit("SET_RESOURCE", property);
    });
  },

  add({ commit, dispatch }, params) {
    return service.add(params, this.$axios).then((property) => {
      commit("SET_RESOURCE", property);
    });
  },

  update({ commit, dispatch }, params) {
    return service.update(params, this.$axios).then((property) => {
      commit("SET_RESOURCE", property);
    });
  },

  destroy({ commit, dispatch }, params) {
    return service.destroy(params, this.$axios);
  },

  activate({ commit, dispatch }, params) {
    return service.activate(params, this.$axios).then((property) => {
      commit("SET_RESOURCE", property);
    });
  },

  deactivate({ commit, dispatch }, params) {
    return service.deactivate(params, this.$axios).then((property) => {
      commit("SET_RESOURCE", property);
    });
  },

  exportExcel({ commit, dispatch }, params = {}) {
    return service.exportExcel(params, this.$axios).then((response) => {
      return response;
    });
  },

  printPdfLetters({ commit, dispatch }, data) {
    return service.printPdfLetters(data, this.$axios);
  },

  exportThumbnails({ commit, dispatch }, data) {
    return service.exportThumbnails(data, this.$axios);
  },

  exportSuperiorCourtProperties({ commit, dispatch }, params) {
    return service.exportSuperiorCourtProperties(params, this.$axios);
  },

  exportPaidProperties({ commit, dispatch }, params) {
    return service.exportPaidProperties(params, this.$axios);
  },

  exportPublicNotices({ commit, dispatch }, params) {
    return service.exportPublicNotices(params, this.$axios);
  },

  exportPdfPublicNotices({ commit, dispatch }, params) {
    return service.exportPdfPublicNotices(params, this.$axios);
  },

  exportSoldProperties({ commit, dispatch }, params) {
    return service.exportSoldProperties(params, this.$axios);
  },

  exportUnsoldProperties({ commit, dispatch }, params) {
    return service.exportUnsoldProperties(params, this.$axios);
  },

  printLettersForSelection({ commit, dispatch }, data) {
    return service.printLettersForSelection(data, this.$axios);
  },

  exportThumbnailsForSelection({ commit, dispatch }, params) {
    return service.exportThumbnailsForSelection(params, this.$axios);
  },

  print({ commit, dispatch }, params) {
    return service.print(params, this.$axios);
  },

  createTransaction({ commit, dispatch }, params) {
    return service.createTransaction(params, this.$axios);
  },

  destroyTransaction({ commit, dispatch }, params) {
    return service.destroyTransaction(params, this.$axios);
  },
  importProperties({ commit, dispatch }, params) {
    return service.importProperties(params, this.$axios).then((response) => {
      return response;
    });
  },
  registrationDivisions({ commit, dispatch }, params = {}) {
    return service.registrationDivisions(params, this.$axios).then((registrationDivisions) => {
      commit("SET_REGISTRATIONS", registrationDivisions);
    });
  },
  printAwardCertificates({ commit, dispatch }, data) {
    return service.printAwardCertificates(data, this.$axios);
  },
};

const getters = {
  list: (state) => state.list,
  listTotal: (state) => state.meta.page.total,
  property: (state) => state.property,
  registrationDivisions: (state) => state.registrationDivisions,
  url: (state) => state.url,
  dropdown: (state) => {
    return state.list.map((property) => ({
      id: property.id,
      name: `${property.firstname} ${property.lastname}`,
    }));
  },
};

const properties = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default properties;
