export default {
  SUBSCRIPTIONS_LIST: "Liste des inscriptions",
  ADD_SUBSCRIPTION: "Ajouter une inscription",
  SUBSCRIPTION_ADDED: "Inscription ajoutée",
  EDIT_SUBSCRIPTION: "Modifier une inscription",
  SUBSCRIPTION_UPDATED: "Inscription mise à jour",
  START_TIME: "Date de debut",
  END_TIME: "Date de fin",
  ACTIVE: "Actif",
  EXPIRED: "Expiré",
  RENEW: "Renouveller",
  SUBSCRIPTION_INVOICE_ADDED: "Facture renouvellement ajoutée",
  VIEW_SUBSCRIPTION: "Inscription",
  DELETE_THIS_SUBSCRIPTION: "Supprimer cette Inscription?",
};
