import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "../i18n/fr/index";
import axios from "axios";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "fr",
  messages: { fr: messages },
});

const loadedLanguages = ["fr"]; // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export async function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  const messages = await import(
    /* webpackChunkName: "lang-[request]" */ `../i18n/${lang}/index.js`
  );
  i18n.setLocaleMessage(lang, messages.default);
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}
